.NewAreaInput {
    border: none;
    background: none;
    border-bottom: 1px solid #BEBEBE;
}

.NewAreaLoader {
    display: inline;
    position: absolute;
    margin-top: 4px;
    margin-left: 4px;
}

.NewAreaReserveCheckbox {
    margin-left: 10px;
}
