.GroupContainer {
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    width: calc((100% / 2) - 15px);
    display: inline-grid;
    margin-left: 15px;
    margin-bottom: 15px;
    overflow-x: auto;
}

.GroupContainer>label:nth-child(1):hover {
    background: #eff8ff;
}

.GroupContent {
    padding: 15px;

}

.GroupContainer>label:nth-child(1) {
    padding: 10px;
    display: block;
    font-weight: bold;
}

.GroupTitleSeparator {
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #D8D8D8;
}

.DeviceManagementTitle {
    display: inline;
}

.TasksContainer {
    padding: 8px;
    background: #2F9BD9;
    color: white;
    border-radius: 8px;
    margin-bottom: 13px;
    display: inline-block;
    margin-left: 15px;
}

.TasksContainer > label {
    cursor: help;
}

.TasksContainer > button {
    border: none;
}

.TasksContainer > * {
    background: transparent;
}

.MediaspotManagementTitle {
    display: block;
    margin-left: 20px;
    margin-bottom: 20px;
}