.ProvisionningContainer {
    padding: 20px!important;
}

.ProvisionningContainer > form > div > div {
    padding: 8px;
}

.ProvisionningContainer > form > div > div > label{
    display: block;
}

.ProvisionningContainer > form > div > div > input{
    width: 400px;
}

.ProvisionningContainer > h4 {
    margin-bottom: 15px;
}


.ProvisionningFieldsTable > tr > td {
    text-align: left;
}

.ProvisionningFieldsTable > tr > td > input, .ProvisionningFieldsTable > tr > td > select{
    width: 350px;
    margin: 8px;
}

.ProvisionningValidateButton, .ProvisionningEditButton {
    height: 40px;
    margin-top: 12px;
    border-radius: 8px;
    color: white;
}

.ProvisionningValidateButton {
    background-color: #69b851;
}

.ProvisionningEditButton {
    background-color: darkorange;
}