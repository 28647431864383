@value colors: "../../globalcss/colors.module.css";
@value v-primary from colors;

@value sizes: "../../globalcss/sizes.module.css";
@value v-menu-width, v-devices-menu-width from sizes;

@value small, medium, large from "../../globalcss/breakpoints.module.css";

.tabContainer {
    position: fixed;
    width: calc(100% - v-menu-width - v-devices-menu-width);
    left: calc(v-menu-width + v-devices-menu-width);
    height: 53px;
    z-index: 120;
    background-color: white;
    padding-right: 40px;
    padding-left: 40px;
    border-bottom: 1px solid #e9e9e9;

    min-width: 950px;
}

@media small {
    .tabContainer{
        width: 100%;
    }

}

@media (max-width: 1380px) {
    .tabContainer {
        width: calc(100vw - 200px - 320px);
        white-space: nowrap;
        min-width: unset;
    }

    .tabContent {
        width: 100%;
        display: flex;
        overflow-x: scroll;
        white-space: nowrap;
    }

    .FilterIcon {
        display: none!important;
    }

    /* .TabsFiltersContainer {
        position: unset!important;
        height: 36px;
        transform: translateY(6px)!important;
    } */

}

.TabsFiltersContainer {
    display: flex;
    position: absolute;
    /* right: 100px; */
    right: 25px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.FilterIcon {
    display: inline-block;
    position: absolute;
    right: 70px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
}

.tabItem {
    display: inline-block;
    padding: 10px;
    padding-top: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}

.tabItem > a {
    vertical-align: middle;
}
.tabItem > label {
    cursor: pointer;
}

.tab {
    color: #a4a4a4;
}


.selectedTab {
    color: v-primary;
}

.selectedTab, .tab {
    font-weight: bold;
}

.tabSelectedLine {
    background-color: v-primary;
    margin-top: 15px;
    margin-left: -8px;
    margin-right: -8px;
    height: 2px;
    margin-bottom: -15px;
}


.TabsIntervalActiveButton {
    background-color: #4185F4!important;
    color: white;
    border-color: #4185F4!important;
}

.TabsIntervalButtonsContainer > button{
    background: white;
    padding: 7px 15px;
    border: 1px solid #979797;
}

.TabsIntervalButtonsContainer > button:first-child{
    border-right: none;
}

.TabsIntervalButtonsContainer > button:last-child{
    border-left: none;
}

.TabsIntervalButtonsContainer > button:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}

.TabsIntervalButtonsContainer > button:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
}


.TabsBrowseAreasBtn {
    background: #FFFFFF;
    border: 1px solid #979797;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 8px;
}

.TabsFiltersContainer > * {
    margin-right: 20px;
}

.DashboardDateShortcutSelect {
    height: 100%;
    background: white;
    border-radius: 8px;
    padding-left: 12px;
    padding-right: 15px;
    border: 1px solid grey;
}
