.DeviceSearchContainer {
    /*height: 100%;*/
    overflow: scroll;
    height: calc(100% - 50px);
}

.DeviceSearchInputContainer > div {
    display: inline;
    margin-left: 10px;
    margin-right: 5px;
}

.DeviceSearchInputContainer{
    margin-top: 5px;
    margin-bottom: 5px;
}

.DeviceSearchResultsSeparator {
    height: 1px;
    width: 100%;
    background: #d0d0d0;
    margin-top: 20px;
    margin-bottom: 20px;
}


.DeviceSearchResultsContainer, .DeviceSearchResultsContainer > table {
    width: 100%;
}

.DeviceSearchResultsContainer > table  {
    margin-top: 10px;
}


.DeviceSearchResultsContainer > table > thead > tr > td {
    border-bottom: 1px solid #d7d7d7;
}

.DeviceSearchResultsContainer > table > tbody > tr > td {
    border-bottom: 1px solid #d7d7d7;
}

.DeviceSearchResultsContainer > table > thead > tr > td {
    text-align: center;
}

.DeviceSearchResultsContainer > table > tbody > tr > td {
    border-right: 1px solid #d7d7d7;
    padding-top: 8px;
    padding-bottom: 8px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
}

.DeviceSearchResultsContainer > table > tbody > tr > td:last-of-type {
    border-right: none;
}

.DeviceSearchResultRowUndraggable {
    background-color: rgba(208, 207, 207, 0.15);
    cursor: default;
}

.DeviceSearchResultRowDraggable {
    cursor: grab;
}

.DeviceSearchResultsContainer > table > tbody > tr:hover:not(.DeviceSearchResultRowUndraggable) {
    background-color: #fbb53c61;
}


.DeviceSearchResultsContainer > table > tbody > tr > td:first-of-type {
    padding: 10px;
}

.DeviceSearchResultsContainer > table > tbody > tr > td:nth-of-type(2) {
    text-transform: capitalize;
    white-space:nowrap;
    text-align: left;
    padding-left: 20px;
    padding-right: 20px;
}


.DeviceSearchResultsContainer > table {
    table-layout: auto;
    border-collapse: collapse;
    width: 100%;
}

.AbsorbingColumn {
    width: 100%;
}

.DeviceSearchValidateButton {
    padding-left: 25px;
    padding-right: 25px;
    padding-top: 7px;
    padding-bottom: 7px;
    border-radius: 7px;
    background-color: #0e7ab9bd;
    color: white;
    box-shadow: 3px 1px 5px #bebebe;
}

.DeviceSearchInputContainer > input {
    background: none;
    border: none;
    border-bottom: 1px solid #bebebe;
}
