.GraphSpinnerContainer {
    width: -moz-fit-content;
    width: fit-content;
    margin-right: auto;
    margin-left: auto;
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
}

.GraphContainer {
    position: relative;
    height: 100%;
}

.RetryButton {
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-top: 10px;
    padding: 5px 15px;
    border-radius: 5px;
    background: #fefefe;
    border: 1px solid lightgray;
}