@value sizes: "../globalcss/sizes.module.css";
@value v-header-height, v-menu-width from sizes;

.AdminAreasContainer {
    position: absolute;
    /* width: calc(100% - v-menu-width); */
    width: 100%;
    height: calc(100% - 60px);
    padding: 15px;

}

.AdminAreasColumns {
    display: flex;
    height: 100%;
}

.AdminAreasTreeContainer {
    width: 35%;
}

.AdminDevicesTreeContainer {
    width: 65%;
}

.AdminAreasTreeContainer, .AdminDevicesTreeContainer {
    background: white;
    padding: 10px;
    box-shadow: 2px 4px 5px #cacaca;
    border-radius: 8px;
    margin: 5px;
}

.AdminAreasHeaderLabel {
    width: calc(100% + 20px);
    height: 50px;
    font-size: 20px;
    color: white;
    display: inline;
}


.AdminAreasTreeContainer > .AdminAreasColumnsHeader {
    background-color: #FBB53C;
}

.AdminDevicesTreeContainer > .AdminAreasColumnsHeader {
    background-color: #0095ff;
}


.AdminAreasSentenceDescription {
    font-size: 18px;
    margin-bottom: 10px;
    display: block;
}

.AdminAreasTreeContent {
    overflow: scroll;
    height: calc(100% - 50px);
}

.AdminAreasColumnsHeader {
    position: relative;

    margin: -10px;
    margin-bottom: 10px;
    padding: 10px;

    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
}

.AdminAreasHideVisibleNodeButton {
    border: 1px solid white;
    position: absolute;
    right: 10px;
    background-color: transparent;
    color: white;
    font-weight: bold;
    border-radius: 7px;
    padding: 4px;
}


.PopupOverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000075;
    z-index: 200;
    top: 0;
    left: 0;
}


.DeviceEditionModalPopupContainer {
    z-index: 210;
    cursor: auto;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    margin: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);

    max-height: 80vh;
    overflow-y: scroll;
}

.DeviceEditionModalPopupContent > * {
    margin-top: 5px;
    margin-bottom: 5px;
}

.DeviceEditionModalPopupContent {
    padding: 10px;
    text-align: center;
}