@value sizes: "../../globalcss/sizes.module.css";
@value v-menu-width, v-devices-menu-width, v-header-height from sizes;

.LocationsContainer {
    padding: 15px;
    position: relative;
    left: v-devices-menu-width;
    width: calc(100% - v-devices-menu-width);
}

.LocationsTreeContainer {
    position: fixed;
    width: v-devices-menu-width;
    height: calc(100% - v-header-height);
    left: v-menu-width;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: scroll;
    box-shadow: 1px 0 3px 0 rgb(0 0 0 / 32%);
    z-index: 160;
}


.ChartContainer, .LargeChartContainer {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
}

.ChartContainer > label, .LargeChartContainer > label {
    font-weight: bold;
    font-size: 16px;
    padding: 20px;
    display: block;
}


.LocationsTitleSeparator {
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #D8D8D8
}

.LocationsMapContainer {
    padding: 15px;
}

