.PopupOverlay{
    position: fixed;
    width: 100%;
    height: 100%;
    background-color: #00000075;
    z-index: 200;
    top: 0;
    left: 0;
}



.DissociationModalPopupContainer {
    z-index: 210;
    cursor: auto;
    padding: 10px;
    background-color: white;
    border-radius: 15px;
    margin: auto;
    top: 50%;
    left: 50%;
    position: absolute;
    transform: translateX(-50%) translateY(-50%);

    max-height: 80vh;
    overflow-y: scroll;
}

.DissociationModalPopupContent > * {
    margin-top: 5px;
    margin-bottom: 5px;
}

.DissociationModalPopupContent {
    padding: 10px;
    text-align: center;
}

.DissociationModalPopupTitle {
    font-size: 23px;
    text-align: center;
    display: block;
}

.DissociationModalSpinner {
    margin-top: 15px;
}

.DissociationModalValidateButton:disabled {
    background: #868686;
}


.DissociationModalValidateButton {
    background: #e04848;
    display: block;
    margin-left: auto;
    margin-right: auto;
    color: white;
    padding: 12px 25px;
    border-radius: 12px;
    font-size: 16px;
    margin-top: 15px;
}

.DissociationModalStoreNodeSelect {
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 5px;
    padding-bottom: 5px;
    border: 1px solid black;
    border-radius: 5px;
}
