.NodeIcon {
    font-size: 12px;
    margin-right: 5px;
    z-index: 50;
}

.TreeLoadingContainer {
    text-align: center;
}

.TreeNode {
    cursor: pointer;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 5px 3px;

    position: relative;
}

.TreeNode:hover {
    background: rgba(176, 213, 236, 0.19);
}

.NodeStatusIcon {
    width: 18px;
    height: 18px;
    display: inline;
    margin-right: 5px;
}

.tooltip {
    z-index: 1500!important;
}

.NodeIconOK {
    filter: invert(57%) sepia(60%) saturate(2477%) hue-rotate(85deg) brightness(116%) contrast(60%); /* green */
}

.NodeIconWarning {
    filter: invert(64%) sepia(88%) saturate(2551%) hue-rotate(0deg) brightness(102%) contrast(103%); /* orange */
}

.NodeIconAlert {
    filter: invert(75%) sepia(51%) saturate(2500%) hue-rotate(310deg) brightness(100%) contrast(90%); /* red */
}

.NodeIconAddButton, .NodeIconEditButton, .NodeIconDissociateButton, .NodeIconDeleteButton {
    padding: 0 5px 0px;
    color: white;
    border-radius: 5px;
    margin-left: 3px;
}

.NodeIconAddButton {
    background-color: #0095ff;
}

.NodeIconEditButton {
    background-color: #ff8c00;
}

.NodeIconDissociateButton, .NodeIconDeleteButton {
    background-color: #fd5858;
}


.NodeIconEditButtonPicto, .NodeIconDissociateButtonPicto, .NodeIconDeleteButtonPicto {
    padding: 2px;
}
