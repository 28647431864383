@value sizes: "../globalcss/sizes.module.css";
@value v-menu-width, v-devices-menu-width, v-header-height from sizes;

.StatisticsTabsContainer {
    position: relative;
    left: v-devices-menu-width;
    width: calc(100% - v-devices-menu-width);
}

.StatisticsContainer{
    position: relative;
    top: 53px;
    padding: 15px;
    left: calc(v-devices-menu-width);
    width: calc(100% - v-devices-menu-width);
}

.StatisticsTabsContainer {
    position: relative;
}

.StatisticsTreeContainer {
    position: fixed;
    width: v-devices-menu-width;
    height: calc(100% - v-header-height);
    left: v-menu-width;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;

    padding-left: 10px;
    padding-right: 10px;
    overflow: scroll;
    box-shadow: 1px 0 3px 0 rgb(0 0 0 / 32%);
    z-index: 160;
}

.ChartContainer, .LargeChartContainer {
    background-color: white;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    transition: 0.3s;
    border-radius: 5px;
}


.ChartContainer > label, .LargeChartContainer > label {
    font-weight: bold;
    font-size: 16px;
    padding: 20px;
    display: block;
}

.ChartGraphContainer {
    padding: 20px;
    padding-left: 10px;
    padding-right: 10px;

    height: calc(100% - 22px - (20px * 2));
}


.ChartTitleSeparator {
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #D8D8D8
}

.StatsGroupContent {
    padding: 20px;
    height: calc(100% - 22px - (20px * 2));
    overflow: auto;
}

.StatsKeyValueContainer {
    padding-top: 5px;
    padding-bottom: 5px;
}

.StatsKeyValueContainer>label:nth-child(1) {
    color: #515157;
    font-weight: 500;
    display: inline;
}

.StatsKeyValueContainer>label:nth-child(2) {
    right: 0;
    float: right;
    font-weight: bold;
}


.StatisticsLoaderContainer, .StatisticsErrorContainer {
    transform: translateY(-50%) translateX(-50%);
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    width: 100%;
}

.StatisticsNoDataContainer {
    padding-left: 10px;
}

.StatisticsGroupContent {
    overflow: auto;
    height: 100%;
}

.StatisticsGroupContent > table{
    width: 100%;
}

.StatisticsGroupContent > table > thead > tr > th:first-of-type, .StatisticsGroupContent > table > tbody > tr > td:first-of-type {
    border-left: none;
}

.StatisticsGroupContent > table > thead > tr > th:last-of-type, .StatisticsGroupContent > table > tbody > tr > td:last-of-type {
    border-right: none;
}

.StatisticsGroupContent > table > thead > tr > th, .StatisticsGroupContent > table > tbody > tr > td {
    font-size: 13px;
}


.StatisticsGroupContent > table > thead > tr > th,  .StatisticsGroupContent > table > tbody > tr > td{
    border: 1px solid #eaeaea;
    border-top: none;
    padding-top: 6px;
    padding-bottom: 6px;
    padding: 6px;
    text-align: center;
}

.RetryButton {
    margin-right: auto;
    margin-left: auto;
    display: block;
    margin-top: 10px;
    padding: 5px 15px;
    border-radius: 5px;
    background: #fefefe;
    border: 1px solid lightgray;
}

.StatsKeyValueContainerBad > label {
    color: red;
}

.tooltip {
    z-index: 1500!important;
}