@value sizes: "../../globalcss/sizes.module.css";
@value v-menu-width, v-devices-menu-width, v-header-height from sizes;

.MediaspotsContainer {
    padding: 15px;
    position: relative;
    left: v-devices-menu-width;
    width: calc(100% - v-devices-menu-width);
}

.MediaspotsTreeContainer {
    position: fixed;
    width: v-devices-menu-width;
    height: calc(100% - v-header-height);
    left: v-menu-width;
    background-color: white;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    padding-right: 10px;
    overflow: scroll;
    box-shadow: 1px 0 3px 0 rgb(0 0 0 / 32%);
    z-index: 160;
}


